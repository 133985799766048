const CURRENCIES = {
    ARS: { code: 'ARS', symbol: '$', fullName: 'Argentine Peso' },
    AUD: { code: 'AUD', symbol: '$', fullName: 'Australian dollar' },
    BGN: { code: 'BGN', symbol: 'лв', fullName: 'Bulgarian lev' },
    BRL: { code: 'BRL', symbol: 'R$', fullName: 'Brazilian real' },
    CAD: { code: 'CAD', symbol: '$', fullName: 'Canadian dollar' },
    CLP: { code: 'CLP', symbol: '$', fullName: 'Chilean pesos' },
    COP: { code: 'COP', symbol: '$', fullName: 'Colombian pesos' },
    EUR: { code: 'EUR', symbol: '€', fullName: 'Euro' },
    GBP: { code: 'GBP', symbol: '£', fullName: 'British pound' },
    HKD: { code: 'HKD', symbol: '$', fullName: 'Hong Kong dollar' },
    MXN: { code: 'MXN', symbol: '$', fullName: 'Mexican pesos' },
    MYR: { code: 'MYR', symbol: 'RM', fullName: 'Malaysian ringgit' },
    NZD: { code: 'NZD', symbol: '$', fullName: 'New Zealand dollar' },
    THB: { code: 'THB', symbol: '฿', fullName: 'Thai baht' },
    TWD: { code: 'TWD', symbol: '$', fullName: 'New Taiwan dollar' },
    USD: { code: 'USD', symbol: '$', fullName: 'United States dollar' },
    ZAR: { code: 'ZAR', symbol: 'R', fullName: 'South African rand' }
};

export default CURRENCIES;
