const COUNTRIES = [
    {
        name: 'Australia',
        code: 'AU',
        tz: 'Australia/Sydney',
        default_currency: 'AUD',
        market: 'aunz',
        code3: 'AUS'
    },
    {
        name: 'Canada',
        code: 'CA',
        tz: 'America/Toronto',
        default_currency: 'CAD',
        market: 'usa',
        code3: 'CAN'
    },
    {
        name: 'New Zealand',
        code: 'NZ',
        tz: 'Pacific/Auckland',
        default_currency: 'NZD',
        market: 'aunz',
        code3: 'NZL'
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        tz: 'Europe/London',
        default_currency: 'GBP',
        market: 'uk_eu',
        code3: 'GBR'
    },
    {
        name: 'United States',
        code: 'US',
        tz: 'America/Chicago',
        default_currency: 'USD',
        market: 'usa',
        code3: 'USA'
    },
    {
        name: 'Afghanistan',
        code: 'AF',
        tz: 'Asia/Kabul',
        default_currency: '',
        market: 'other',
        code3: 'AFG'
    },
    {
        name: 'Åland Islands',
        code: 'AX',
        tz: 'Europe/Helsinki',
        default_currency: '',
        market: 'other',
        code3: 'ALA'
    },
    {
        name: 'Albania',
        code: 'AL',
        tz: 'Europe/Tirane',
        default_currency: '',
        market: 'other',
        code3: 'ALB'
    },
    {
        name: 'Algeria',
        code: 'DZ',
        tz: 'Africa/Algiers',
        default_currency: '',
        market: 'other',
        code3: 'DZA'
    },
    {
        name: 'American Samoa',
        code: 'AS',
        tz: 'Pacific/Pago_Pago',
        default_currency: '',
        market: 'other',
        code3: 'ASM'
    },
    {
        name: 'AndorrA',
        code: 'AD',
        tz: 'Europe/Andorra',
        default_currency: '',
        market: 'other',
        code3: 'AND'
    },
    {
        name: 'Angola',
        code: 'AO',
        tz: 'Africa/Lagos',
        default_currency: '',
        market: 'other',
        code3: 'AGO'
    },
    {
        name: 'Anguilla',
        code: 'AI',
        tz: 'America/Anguilla',
        default_currency: '',
        market: 'other',
        code3: 'AIA'
    },
    {
        name: 'Antarctica',
        code: 'AQ',
        tz: 'Antarctica/Casey',
        default_currency: '',
        market: 'other',
        code3: 'ATA'
    },
    {
        name: 'Antigua and Barbuda',
        code: 'AG',
        tz: 'America/Antigua',
        default_currency: '',
        market: 'other',
        code3: 'ATG'
    },
    {
        name: 'Argentina',
        code: 'AR',
        tz: 'America/Argentina/Buenos_Aires',
        default_currency: 'ARS',
        market: 'other',
        code3: 'ARG'
    },
    {
        name: 'Armenia',
        code: 'AM',
        tz: 'Asia/Yerevan',
        default_currency: '',
        market: 'other',
        code3: 'ARM'
    },
    {
        name: 'Aruba',
        code: 'AW',
        tz: 'America/Aruba',
        default_currency: '',
        market: 'other',
        code3: 'ABW'
    },
    {
        name: 'Austria',
        code: 'AT',
        tz: 'Europe/Vienna',
        default_currency: '',
        market: 'other',
        code3: 'AUT'
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
        tz: 'Asia/Baku',
        default_currency: '',
        market: 'other',
        code3: 'AZE'
    },
    {
        name: 'Bahamas',
        code: 'BS',
        tz: 'America/Nassau',
        default_currency: '',
        market: 'other',
        code3: 'BHS'
    },
    {
        name: 'Bahrain',
        code: 'BH',
        tz: 'Asia/Bahrain',
        default_currency: '',
        market: 'other',
        code3: 'BHR'
    },
    {
        name: 'Bangladesh',
        code: 'BD',
        tz: 'Asia/Dhaka',
        default_currency: '',
        market: 'other',
        code3: 'BGD'
    },
    {
        name: 'Barbados',
        code: 'BB',
        tz: 'America/Barbados',
        default_currency: '',
        market: 'other',
        code3: 'BRB'
    },
    {
        name: 'Belarus',
        code: 'BY',
        tz: 'Europe/Minsk',
        default_currency: '',
        market: 'other',
        code3: 'BLR'
    },
    {
        name: 'Belgium',
        code: 'BE',
        tz: 'Europe/Brussels',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'BEL'
    },
    {
        name: 'Belize',
        code: 'BZ',
        tz: 'America/Belize',
        default_currency: '',
        market: 'other',
        code3: 'BLZ'
    },
    {
        name: 'Benin',
        code: 'BJ',
        tz: 'Africa/Lagos',
        default_currency: '',
        market: 'other',
        code3: 'BEN'
    },
    {
        name: 'Bermuda',
        code: 'BM',
        tz: 'Atlantic/Bermuda',
        default_currency: '',
        market: 'other',
        code3: 'BMU'
    },
    {
        name: 'Bhutan',
        code: 'BT',
        tz: 'Asia/Thimphu',
        default_currency: '',
        market: 'other',
        code3: 'BTN'
    },
    {
        name: 'Bolivia',
        code: 'BO',
        tz: 'America/La_Paz',
        default_currency: '',
        market: 'other',
        code3: 'BOL'
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        tz: 'Europe/Belgrade',
        default_currency: '',
        market: 'other',
        code3: 'BIH'
    },
    {
        name: 'Botswana',
        code: 'BW',
        tz: 'Africa/Gaborone',
        default_currency: '',
        market: 'other',
        code3: 'BWA'
    },
    {
        name: 'Bouvet Island',
        code: 'BV',
        default_currency: '',
        market: 'other',
        code3: 'BVT'
    },
    {
        name: 'Brazil',
        code: 'BR',
        tz: 'America/Araguaina',
        default_currency: 'BRL',
        market: 'other',
        code3: 'BRA'
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        tz: 'Indian/Chagos',
        default_currency: '',
        market: 'other',
        code3: 'IOT'
    },
    {
        name: 'Brunei Darussalam',
        code: 'BN',
        tz: 'Asia/Brunei',
        default_currency: '',
        market: 'other',
        code3: 'BRN'
    },
    {
        name: 'Bulgaria',
        code: 'BG',
        tz: 'Europe/Sofia',
        default_currency: 'BGN',
        market: 'other',
        code3: 'BGR'
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'BFA'
    },
    {
        name: 'Burundi',
        code: 'BI',
        tz: 'Africa/Bujumbura',
        default_currency: '',
        market: 'other',
        code3: 'BDI'
    },
    {
        name: 'Cambodia',
        code: 'KH',
        tz: 'Asia/Bangkok',
        default_currency: '',
        market: 'other',
        code3: 'KHM'
    },
    {
        name: 'Cameroon',
        code: 'CM',
        tz: 'Africa/Douala',
        default_currency: '',
        market: 'other',
        code3: 'CMR'
    },
    {
        name: 'Cape Verde',
        code: 'CV',
        tz: 'Atlantic/Cape_Verde',
        default_currency: '',
        market: 'other',
        code3: 'CPV'
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
        tz: 'America/Cayman',
        default_currency: '',
        market: 'other',
        code3: 'CYM'
    },
    {
        name: 'Central African Republic',
        code: 'CF',
        tz: 'Africa/Bangui',
        default_currency: '',
        market: 'other',
        code3: 'CAF'
    },
    {
        name: 'Chad',
        code: 'TD',
        tz: 'Africa/Ndjamena',
        default_currency: '',
        market: 'other',
        code3: 'TCD'
    },
    {
        name: 'Chile',
        code: 'CL',
        tz: 'America/Punta_Arenas',
        default_currency: 'CLP',
        market: 'other',
        code3: 'CHL'
    },
    {
        name: 'China',
        code: 'CN',
        tz: 'Asia/Shanghai',
        default_currency: '',
        market: 'other',
        code3: 'CHN'
    },
    {
        name: 'Christmas Island',
        code: 'CX',
        tz: 'Indian/Christmas',
        default_currency: '',
        market: 'other',
        code3: 'CXR'
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        tz: 'Indian/Cocos',
        default_currency: '',
        market: 'other',
        code3: 'CCK'
    },
    {
        name: 'Colombia',
        code: 'CO',
        tz: 'America/Bogota',
        default_currency: 'COP',
        market: 'other',
        code3: 'COL'
    },
    {
        name: 'Comoros',
        code: 'KM',
        tz: 'Africa/Nairobi',
        default_currency: '',
        market: 'other',
        code3: 'COM'
    },
    {
        name: 'Congo',
        code: 'CG',
        tz: 'Africa/Brazzaville',
        default_currency: '',
        market: 'other',
        code3: 'COG'
    },
    {
        name: 'Congo, The Democratic Republic of the',
        code: 'CD',
        tz: 'Africa/Kinshasa',
        default_currency: '',
        market: 'other',
        code3: 'COD'
    },
    {
        name: 'Cook Islands',
        code: 'CK',
        tz: 'Pacific/Rarotonga',
        default_currency: '',
        market: 'other',
        code3: 'COK'
    },
    {
        name: 'Costa Rica',
        code: 'CR',
        tz: 'America/Costa_Rica',
        default_currency: '',
        market: 'other',
        code3: 'CRI'
    },
    {
        name: "Cote D'Ivoire",
        code: 'CI',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'CIV'
    },
    {
        name: 'Croatia',
        code: 'HR',
        tz: 'Europe/Belgrade',
        default_currency: '',
        market: 'other',
        code3: 'HRV'
    },
    {
        name: 'Cuba',
        code: 'CU',
        tz: 'America/Havana',
        default_currency: '',
        market: 'other',
        code3: 'CUB'
    },
    {
        name: 'Cyprus',
        code: 'CY',
        tz: 'Asia/Famagusta',
        default_currency: '',
        market: 'other',
        code3: 'CYP'
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
        tz: 'Europe/Prague',
        default_currency: '',
        market: 'other',
        code3: 'CZE'
    },
    {
        name: 'Denmark',
        code: 'DK',
        tz: 'Europe/Copenhagen',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'DNK'
    },
    {
        name: 'Djibouti',
        code: 'DJ',
        tz: 'Africa/Djibouti',
        default_currency: '',
        market: 'other',
        code3: 'DJI'
    },
    {
        name: 'Dominica',
        code: 'DM',
        tz: 'America/Dominica',
        default_currency: '',
        market: 'other',
        code3: 'DMA'
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
        tz: 'America/Santo_Domingo',
        default_currency: '',
        market: 'other',
        code3: 'DOM'
    },
    {
        name: 'Ecuador',
        code: 'EC',
        tz: 'America/Guayaquil',
        default_currency: '',
        market: 'other',
        code3: 'ECU'
    },
    {
        name: 'Egypt',
        code: 'EG',
        tz: 'Africa/Cairo',
        default_currency: '',
        market: 'other',
        code3: 'EGY'
    },
    {
        name: 'El Salvador',
        code: 'SV',
        tz: 'America/El_Salvador',
        default_currency: '',
        market: 'other',
        code3: 'SLV'
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
        tz: 'Africa/Lagos',
        default_currency: '',
        market: 'other',
        code3: 'GNQ'
    },
    {
        name: 'Eritrea',
        code: 'ER',
        tz: 'Africa/Asmara',
        default_currency: '',
        market: 'other',
        code3: 'ERI'
    },
    {
        name: 'Estonia',
        code: 'EE',
        tz: 'Europe/Tallinn',
        default_currency: '',
        market: 'other',
        code3: 'EST'
    },
    {
        name: 'Ethiopia',
        code: 'ET',
        tz: 'Africa/Addis_Ababa',
        default_currency: '',
        market: 'other',
        code3: 'ETH'
    },
    {
        name: 'Falkland Islands (Malvinas)',
        code: 'FK',
        tz: 'Atlantic/Stanley',
        default_currency: '',
        market: 'other',
        code3: 'FLK'
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
        tz: 'Atlantic/Faroe',
        default_currency: '',
        market: 'other',
        code3: 'FRO'
    },
    {
        name: 'Fiji',
        code: 'FJ',
        tz: 'Pacific/Fiji',
        default_currency: '',
        market: 'other',
        code3: 'FJI'
    },
    {
        name: 'Finland',
        code: 'FI',
        tz: 'Europe/Helsinki',
        default_currency: '',
        market: 'other',
        code3: 'FIN'
    },
    {
        name: 'France',
        code: 'FR',
        tz: 'Europe/Paris',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'FRA'
    },
    {
        name: 'French Guiana',
        code: 'GF',
        tz: 'America/Cayenne',
        default_currency: '',
        market: 'other',
        code3: 'GUF'
    },
    {
        name: 'French Polynesia',
        code: 'PF',
        tz: 'Pacific/Gambier',
        default_currency: '',
        market: 'other',
        code3: 'PYF'
    },
    {
        name: 'French Southern Territories',
        code: 'TF',
        tz: 'Indian/Kerguelen',
        default_currency: '',
        market: 'other',
        code3: 'ATF'
    },
    {
        name: 'Gabon',
        code: 'GA',
        tz: 'Africa/Lagos',
        default_currency: '',
        market: 'other',
        code3: 'GAB'
    },
    {
        name: 'Gambia',
        code: 'GM',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'GMB'
    },
    {
        name: 'Georgia',
        code: 'GE',
        tz: 'Asia/Tbilisi',
        default_currency: '',
        market: 'other',
        code3: 'GEO'
    },
    {
        name: 'Germany',
        code: 'DE',
        tz: 'Europe/Berlin',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'DEU'
    },
    {
        name: 'Ghana',
        code: 'GH',
        tz: 'Africa/Accra',
        default_currency: '',
        market: 'other',
        code3: 'GHA'
    },
    {
        name: 'Gibraltar',
        code: 'GI',
        tz: 'Europe/Gibraltar',
        default_currency: '',
        market: 'other',
        code3: 'GIB'
    },
    {
        name: 'Greece',
        code: 'GR',
        tz: 'Europe/Athens',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'GRC'
    },
    {
        name: 'Greenland',
        code: 'GL',
        tz: 'America/Danmarkshavn',
        default_currency: '',
        market: 'other',
        code3: 'GRL'
    },
    {
        name: 'Grenada',
        code: 'GD',
        tz: 'America/Grenada',
        default_currency: '',
        market: 'other',
        code3: 'GRD'
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
        tz: 'America/Guadeloupe',
        default_currency: '',
        market: 'other',
        code3: 'GLP'
    },
    {
        name: 'Guam',
        code: 'GU',
        tz: 'Pacific/Guam',
        default_currency: '',
        market: 'other',
        code3: 'GUM'
    },
    {
        name: 'Guatemala',
        code: 'GT',
        tz: 'America/Guatemala',
        default_currency: '',
        market: 'other',
        code3: 'GTM'
    },
    {
        name: 'Guernsey',
        code: 'GG',
        tz: 'Europe/Guernsey',
        default_currency: '',
        market: 'other',
        code3: 'GGY'
    },
    {
        name: 'Guinea',
        code: 'GN',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'GIN'
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
        tz: 'Africa/Bissau',
        default_currency: '',
        market: 'other',
        code3: 'GNB'
    },
    {
        name: 'Guyana',
        code: 'GY',
        tz: 'America/Guyana',
        default_currency: '',
        market: 'other',
        code3: 'GUY'
    },
    {
        name: 'Haiti',
        code: 'HT',
        tz: 'America/Port-au-Prince',
        default_currency: '',
        market: 'other',
        code3: 'HTI'
    },
    {
        name: 'Heard Island and Mcdonald Islands',
        code: 'HM',
        default_currency: '',
        market: 'other',
        code3: 'HMD'
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'VA',
        tz: 'Europe/Rome',
        default_currency: '',
        market: 'other',
        code3: 'VAT'
    },
    {
        name: 'Honduras',
        code: 'HN',
        tz: 'America/Tegucigalpa',
        default_currency: '',
        market: 'other',
        code3: 'HND'
    },
    {
        name: 'Hong Kong',
        code: 'HK',
        tz: 'Asia/Hong_Kong',
        default_currency: 'HKD',
        market: 'other',
        code3: 'HKG'
    },
    {
        name: 'Hungary',
        code: 'HU',
        tz: 'Europe/Budapest',
        default_currency: '',
        market: 'other',
        code3: 'HUN'
    },
    {
        name: 'Iceland',
        code: 'IS',
        tz: 'Atlantic/Reykjavik',
        default_currency: '',
        market: 'other',
        code3: 'ISL'
    },
    {
        name: 'India',
        code: 'IN',
        tz: 'Asia/Kolkata',
        default_currency: '',
        market: 'other',
        code3: 'IND'
    },
    {
        name: 'Indonesia',
        code: 'ID',
        tz: 'Asia/Jakarta',
        default_currency: '',
        market: 'other',
        code3: 'IDN'
    },
    {
        name: 'Iran, Islamic Republic Of',
        code: 'IR',
        tz: 'Asia/Tehran',
        default_currency: '',
        market: 'other',
        code3: 'IRN'
    },
    {
        name: 'Iraq',
        code: 'IQ',
        tz: 'Asia/Baghdad',
        default_currency: '',
        market: 'other',
        code3: 'IRQ'
    },
    {
        name: 'Ireland',
        code: 'IE',
        tz: 'Europe/Dublin',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'IRL'
    },
    {
        name: 'Isle of Man',
        code: 'IM',
        tz: 'Europe/Isle_of_Man',
        default_currency: '',
        market: 'other',
        code3: 'IMN'
    },
    {
        name: 'Israel',
        code: 'IL',
        tz: 'Asia/Jerusalem',
        default_currency: '',
        market: 'other',
        code3: 'ISR'
    },
    {
        name: 'Italy',
        code: 'IT',
        tz: 'Europe/Rome',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'ITA'
    },
    {
        name: 'Jamaica',
        code: 'JM',
        tz: 'America/Jamaica',
        default_currency: '',
        market: 'other',
        code3: 'JAM'
    },
    {
        name: 'Japan',
        code: 'JP',
        tz: 'Asia/Tokyo',
        default_currency: '',
        market: 'other',
        code3: 'JPN'
    },
    {
        name: 'Jersey',
        code: 'JE',
        tz: 'Europe/Jersey',
        default_currency: '',
        market: 'other',
        code3: 'JEY'
    },
    {
        name: 'Jordan',
        code: 'JO',
        tz: 'Asia/Amman',
        default_currency: '',
        market: 'other',
        code3: 'JOR'
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
        tz: 'Asia/Almaty',
        default_currency: '',
        market: 'other',
        code3: 'KAZ'
    },
    {
        name: 'Kenya',
        code: 'KE',
        tz: 'Africa/Nairobi',
        default_currency: '',
        market: 'other',
        code3: 'KEN'
    },
    {
        name: 'Kiribati',
        code: 'KI',
        tz: 'Pacific/Enderbury',
        default_currency: '',
        market: 'other',
        code3: 'KIR'
    },
    {
        name: "Korea, Democratic People'S Republic of",
        code: 'KP',
        tz: 'Asia/Pyongyang',
        default_currency: '',
        market: 'other',
        code3: 'PRK'
    },
    {
        name: 'Korea, Republic of',
        code: 'KR',
        tz: 'Asia/Seoul',
        default_currency: '',
        market: 'other',
        code3: 'KOR'
    },
    {
        name: 'Kuwait',
        code: 'KW',
        tz: 'Asia/Kuwait',
        default_currency: '',
        market: 'other',
        code3: 'KWT'
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
        tz: 'Asia/Bishkek',
        default_currency: '',
        market: 'other',
        code3: 'KGZ'
    },
    {
        name: "Lao People'S Democratic Republic",
        code: 'LA',
        tz: 'Asia/Bangkok',
        default_currency: '',
        market: 'other',
        code3: 'LAO'
    },
    {
        name: 'Latvia',
        code: 'LV',
        tz: 'Europe/Riga',
        default_currency: '',
        market: 'other',
        code3: 'LVA'
    },
    {
        name: 'Lebanon',
        code: 'LB',
        tz: 'Asia/Beirut',
        default_currency: '',
        market: 'other',
        code3: 'LBN'
    },
    {
        name: 'Lesotho',
        code: 'LS',
        tz: 'Africa/Johannesburg',
        default_currency: '',
        market: 'other',
        code3: 'LSO'
    },
    {
        name: 'Liberia',
        code: 'LR',
        tz: 'Africa/Monrovia',
        default_currency: '',
        market: 'other',
        code3: 'LBR'
    },
    {
        name: 'Libyan Arab Jamahiriya',
        code: 'LY',
        tz: 'Africa/Tripoli',
        default_currency: '',
        market: 'other',
        code3: 'LBY'
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
        tz: 'Europe/Vaduz',
        default_currency: '',
        market: 'other',
        code3: 'LIE'
    },
    {
        name: 'Lithuania',
        code: 'LT',
        tz: 'Europe/Vilnius',
        default_currency: '',
        market: 'other',
        code3: 'LTU'
    },
    {
        name: 'Luxembourg',
        code: 'LU',
        tz: 'Europe/Luxembourg',
        default_currency: '',
        market: 'other',
        code3: 'LUX'
    },
    {
        name: 'Macao',
        code: 'MO',
        tz: 'Asia/Macau',
        default_currency: '',
        market: 'other',
        code3: 'MAC'
    },
    {
        name: 'Macedonia, The Former Yugoslav Republic of',
        code: 'MK',
        tz: 'Europe/Belgrade',
        default_currency: '',
        market: 'other',
        code3: 'MKD'
    },
    {
        name: 'Madagascar',
        code: 'MG',
        tz: 'Africa/Nairobi',
        default_currency: '',
        market: 'other',
        code3: 'MDG'
    },
    {
        name: 'Malawi',
        code: 'MW',
        tz: 'Africa/Blantyre',
        default_currency: '',
        market: 'other',
        code3: 'MWI'
    },
    {
        name: 'Malaysia',
        code: 'MY',
        tz: 'Asia/Kuala_Lumpur',
        default_currency: 'MYR',
        market: 'other',
        code3: 'MYS'
    },
    {
        name: 'Maldives',
        code: 'MV',
        tz: 'Indian/Maldives',
        default_currency: '',
        market: 'other',
        code3: 'MDV'
    },
    {
        name: 'Mali',
        code: 'ML',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'MLI'
    },
    {
        name: 'Malta',
        code: 'MT',
        tz: 'Europe/Malta',
        default_currency: '',
        market: 'other',
        code3: 'MLT'
    },
    {
        name: 'Marshall Islands',
        code: 'MH',
        tz: 'Pacific/Kwajalein',
        default_currency: '',
        market: 'other',
        code3: 'MHL'
    },
    {
        name: 'Martinique',
        code: 'MQ',
        tz: 'America/Martinique',
        default_currency: '',
        market: 'other',
        code3: 'MTQ'
    },
    {
        name: 'Mauritania',
        code: 'MR',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'MRT'
    },
    {
        name: 'Mauritius',
        code: 'MU',
        tz: 'Indian/Mauritius',
        default_currency: '',
        market: 'other',
        code3: 'MUS'
    },
    {
        name: 'Mayotte',
        code: 'YT',
        tz: 'Africa/Nairobi',
        default_currency: '',
        market: 'other',
        code3: 'MYT'
    },
    {
        name: 'Mexico',
        code: 'MX',
        tz: 'America/Bahia_Banderas',
        default_currency: 'MXN',
        market: 'other',
        code3: 'MEX'
    },
    {
        name: 'Micronesia, Federated States of',
        code: 'FM',
        tz: 'Pacific/Chuuk',
        default_currency: '',
        market: 'other',
        code3: 'FSM'
    },
    {
        name: 'Moldova, Republic of',
        code: 'MD',
        tz: 'Europe/Chisinau',
        default_currency: '',
        market: 'other',
        code3: 'MDA'
    },
    {
        name: 'Monaco',
        code: 'MC',
        tz: 'Europe/Monaco',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'MCO'
    },
    {
        name: 'Mongolia',
        code: 'MN',
        tz: 'Asia/Choibalsan',
        default_currency: '',
        market: 'other',
        code3: 'MNG'
    },
    {
        name: 'Montenegro',
        code: 'ME',
        default_currency: '',
        market: 'other',
        code3: 'MNE'
    },
    {
        name: 'Montserrat',
        code: 'MS',
        tz: 'America/Montserrat',
        default_currency: '',
        market: 'other',
        code3: 'MSR'
    },
    {
        name: 'Morocco',
        code: 'MA',
        tz: 'Africa/Casablanca',
        default_currency: '',
        market: 'other',
        code3: 'MAR'
    },
    {
        name: 'Mozambique',
        code: 'MZ',
        tz: 'Africa/Maputo',
        default_currency: '',
        market: 'other',
        code3: 'MOZ'
    },
    {
        name: 'Myanmar',
        code: 'MM',
        tz: 'Asia/Yangon',
        default_currency: '',
        market: 'other',
        code3: 'MMR'
    },
    {
        name: 'Namibia',
        code: 'NA',
        tz: 'Africa/Windhoek',
        default_currency: '',
        market: 'other',
        code3: 'NAM'
    },
    {
        name: 'Nauru',
        code: 'NR',
        tz: 'Pacific/Nauru',
        default_currency: '',
        market: 'other',
        code3: 'NRU'
    },
    {
        name: 'Nepal',
        code: 'NP',
        tz: 'Asia/Kathmandu',
        default_currency: '',
        market: 'other',
        code3: 'NPL'
    },
    {
        name: 'Netherlands',
        code: 'NL',
        tz: 'Europe/Amsterdam',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'NLD'
    },
    {
        name: 'Netherlands Antilles',
        code: 'AN',
        default_currency: '',
        market: 'other',
        code3: 'ANT'
    },
    {
        name: 'New Caledonia',
        code: 'NC',
        tz: 'Pacific/Noumea',
        default_currency: '',
        market: 'other',
        code3: 'NCL'
    },
    {
        name: 'Nicaragua',
        code: 'NI',
        tz: 'America/Managua',
        default_currency: '',
        market: 'other',
        code3: 'NIC'
    },
    {
        name: 'Niger',
        code: 'NE',
        tz: 'Africa/Lagos',
        default_currency: '',
        market: 'other',
        code3: 'NER'
    },
    {
        name: 'Nigeria',
        code: 'NG',
        tz: 'Africa/Lagos',
        default_currency: '',
        market: 'other',
        code3: 'NGA'
    },
    {
        name: 'Niue',
        code: 'NU',
        tz: 'Pacific/Niue',
        default_currency: '',
        market: 'other',
        code3: 'NIU'
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
        tz: 'Pacific/Norfolk',
        default_currency: '',
        market: 'other',
        code3: 'NFK'
    },
    {
        name: 'Northern Mariana Islands',
        code: 'MP',
        tz: 'Pacific/Guam',
        default_currency: '',
        market: 'other',
        code3: 'MNP'
    },
    {
        name: 'Norway',
        code: 'NO',
        tz: 'Europe/Oslo',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'NOR'
    },
    {
        name: 'Oman',
        code: 'OM',
        tz: 'Asia/Dubai',
        default_currency: '',
        market: 'other',
        code3: 'OMN'
    },
    {
        name: 'Pakistan',
        code: 'PK',
        tz: 'Asia/Karachi',
        default_currency: '',
        market: 'other',
        code3: 'PAK'
    },
    {
        name: 'Palau',
        code: 'PW',
        tz: 'Pacific/Palau',
        default_currency: '',
        market: 'other',
        code3: 'PLW'
    },
    {
        name: 'Palestinian Territory, Occupied',
        code: 'PS',
        tz: 'Asia/Gaza',
        default_currency: '',
        market: 'other',
        code3: 'PSE'
    },
    {
        name: 'Panama',
        code: 'PA',
        tz: 'America/Panama',
        default_currency: '',
        market: 'other',
        code3: 'PAN'
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
        tz: 'Pacific/Bougainville',
        default_currency: '',
        market: 'other',
        code3: 'PNG'
    },
    {
        name: 'Paraguay',
        code: 'PY',
        tz: 'America/Asuncion',
        default_currency: '',
        market: 'other',
        code3: 'PRY'
    },
    {
        name: 'Peru',
        code: 'PE',
        tz: 'America/Lima',
        default_currency: '',
        market: 'other',
        code3: 'PER'
    },
    {
        name: 'Philippines',
        code: 'PH',
        tz: 'Asia/Manila',
        default_currency: '',
        market: 'other',
        code3: 'PHL'
    },
    {
        name: 'Pitcairn',
        code: 'PN',
        tz: 'Pacific/Pitcairn',
        default_currency: '',
        market: 'other',
        code3: 'PCN'
    },
    {
        name: 'Poland',
        code: 'PL',
        tz: 'Europe/Warsaw',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'POL'
    },
    {
        name: 'Portugal',
        code: 'PT',
        tz: 'Atlantic/Azores',
        default_currency: '',
        market: 'other',
        code3: 'PRT'
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
        tz: 'America/Puerto_Rico',
        default_currency: '',
        market: 'other',
        code3: 'PRI'
    },
    {
        name: 'Qatar',
        code: 'QA',
        tz: 'Asia/Qatar',
        default_currency: '',
        market: 'other',
        code3: 'QAT'
    },
    {
        name: 'Reunion',
        code: 'RE',
        tz: 'Indian/Reunion',
        default_currency: '',
        market: 'other',
        code3: 'REU'
    },
    {
        name: 'Romania',
        code: 'RO',
        tz: 'Europe/Bucharest',
        default_currency: '',
        market: 'other',
        code3: 'ROU'
    },
    {
        name: 'Russian Federation',
        code: 'RU',
        tz: 'Asia/Anadyr',
        default_currency: '',
        market: 'other',
        code3: 'RUS'
    },
    {
        name: 'RWANDA',
        code: 'RW',
        tz: 'Africa/Kigali',
        default_currency: '',
        market: 'other',
        code3: 'RWA'
    },
    {
        name: 'Saint Helena',
        code: 'SH',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'SHN'
    },
    {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        tz: 'America/Port_of_Spain',
        default_currency: '',
        market: 'other',
        code3: 'KNA'
    },
    {
        name: 'Saint Lucia',
        code: 'LC',
        tz: 'America/Port_of_Spain',
        default_currency: '',
        market: 'other',
        code3: 'LCA'
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        tz: 'America/Miquelon',
        default_currency: '',
        market: 'other',
        code3: 'SPM'
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
        tz: 'America/Port_of_Spain',
        default_currency: '',
        market: 'other',
        code3: 'VCT'
    },
    {
        name: 'Samoa',
        code: 'WS',
        tz: 'Pacific/Apia',
        default_currency: '',
        market: 'other',
        code3: 'WSM'
    },
    {
        name: 'San Marino',
        code: 'SM',
        tz: 'Europe/Rome',
        default_currency: '',
        market: 'other',
        code3: 'SMR'
    },
    {
        name: 'Sao Tome and Principe',
        code: 'ST',
        tz: 'Africa/Sao_Tome',
        default_currency: '',
        market: 'other',
        code3: 'STP'
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
        tz: 'Asia/Riyadh',
        default_currency: '',
        market: 'other',
        code3: 'SAU'
    },
    {
        name: 'Senegal',
        code: 'SN',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'SEN'
    },
    {
        name: 'Serbia',
        code: 'RS',
        default_currency: '',
        market: 'other',
        code3: 'SRB'
    },
    {
        name: 'Seychelles',
        code: 'SC',
        tz: 'Indian/Mahe',
        default_currency: '',
        market: 'other',
        code3: 'SYC'
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'SLE'
    },
    {
        name: 'Singapore',
        code: 'SG',
        tz: 'Asia/Singapore',
        default_currency: '',
        market: 'other',
        code3: 'SGP'
    },
    {
        name: 'Slovakia',
        code: 'SK',
        tz: 'Europe/Bratislava',
        default_currency: '',
        market: 'other',
        code3: 'SVK'
    },
    {
        name: 'Slovenia',
        code: 'SI',
        tz: 'Europe/Belgrade',
        default_currency: '',
        market: 'other',
        code3: 'SVN'
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
        tz: 'Pacific/Guadalcanal',
        default_currency: '',
        market: 'other',
        code3: 'SLB'
    },
    {
        name: 'Somalia',
        code: 'SO',
        tz: 'Africa/Mogadishu',
        default_currency: '',
        market: 'other',
        code3: 'SOM'
    },
    {
        name: 'South Africa',
        code: 'ZA',
        tz: 'Africa/Johannesburg',
        default_currency: 'ZAR',
        market: 'other',
        code3: 'ZAF'
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
        tz: 'Atlantic/South_Georgia',
        default_currency: '',
        market: 'other',
        code3: 'SGS'
    },
    {
        name: 'Spain',
        code: 'ES',
        tz: 'Europe/Madrid',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'ESP'
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
        tz: 'Asia/Colombo',
        default_currency: '',
        market: 'other',
        code3: 'LKA'
    },
    {
        name: 'Sudan',
        code: 'SD',
        tz: 'Africa/Khartoum',
        default_currency: '',
        market: 'other',
        code3: 'SDN'
    },
    {
        name: 'Suriname',
        code: 'SR',
        tz: 'America/Paramaribo',
        default_currency: '',
        market: 'other',
        code3: 'SUR'
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'SJ',
        tz: 'Arctic/Longyearbyen',
        default_currency: '',
        market: 'other',
        code3: 'SJM'
    },
    {
        name: 'Swaziland',
        code: 'SZ',
        tz: 'Africa/Johannesburg',
        default_currency: '',
        market: 'other',
        code3: 'SWZ'
    },
    {
        name: 'Sweden',
        code: 'SE',
        tz: 'Europe/Stockholm',
        default_currency: 'EUR',
        market: 'uk_eu',
        code3: 'SWE'
    },
    {
        name: 'Switzerland',
        code: 'CH',
        tz: 'Europe/Zurich',
        default_currency: '',
        market: 'other',
        code3: 'CHE'
    },
    {
        name: 'Syrian Arab Republic',
        code: 'SY',
        tz: 'Asia/Damascus',
        default_currency: '',
        market: 'other',
        code3: 'SYR'
    },
    {
        name: 'Taiwan',
        code: 'TW',
        tz: 'Asia/Taipei',
        default_currency: 'TWD',
        market: 'other',
        code3: 'TWN'
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
        tz: 'Asia/Dushanbe',
        default_currency: '',
        market: 'other',
        code3: 'TJK'
    },
    {
        name: 'Tanzania, United Republic of',
        code: 'TZ',
        tz: 'Africa/Dar_es_Salaam',
        default_currency: '',
        market: 'other',
        code3: 'TZA'
    },
    {
        name: 'Thailand',
        code: 'TH',
        tz: 'Asia/Bangkok',
        default_currency: 'THB',
        market: 'other',
        code3: 'THA'
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
        tz: 'Asia/Dili',
        default_currency: '',
        market: 'other',
        code3: 'TLS'
    },
    {
        name: 'Togo',
        code: 'TG',
        tz: 'Africa/Abidjan',
        default_currency: '',
        market: 'other',
        code3: 'TGO'
    },
    {
        name: 'Tokelau',
        code: 'TK',
        tz: 'Pacific/Fakaofo',
        default_currency: '',
        market: 'other',
        code3: 'TKL'
    },
    {
        name: 'Tonga',
        code: 'TO',
        tz: 'Pacific/Tongatapu',
        default_currency: '',
        market: 'other',
        code3: 'TON'
    },
    {
        name: 'Trinidad and Tobago',
        code: 'TT',
        tz: 'America/Port_of_Spain',
        default_currency: '',
        market: 'other',
        code3: 'TTO'
    },
    {
        name: 'Tunisia',
        code: 'TN',
        tz: 'Africa/Tunis',
        default_currency: '',
        market: 'other',
        code3: 'TUN'
    },
    {
        name: 'Turkey',
        code: 'TR',
        tz: 'Europe/Istanbul',
        default_currency: '',
        market: 'other',
        code3: 'TUR'
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
        tz: 'Asia/Ashgabat',
        default_currency: '',
        market: 'other',
        code3: 'TKM'
    },
    {
        name: 'Turks and Caicos Islands',
        code: 'TC',
        tz: 'America/Grand_Turk',
        default_currency: '',
        market: 'other',
        code3: 'TCA'
    },
    {
        name: 'Tuvalu',
        code: 'TV',
        tz: 'Pacific/Funafuti',
        default_currency: '',
        market: 'other',
        code3: 'TUV'
    },
    {
        name: 'Uganda',
        code: 'UG',
        tz: 'Africa/Kampala',
        default_currency: '',
        market: 'other',
        code3: 'UGA'
    },
    {
        name: 'Ukraine',
        code: 'UA',
        tz: 'Europe/Kiev',
        default_currency: '',
        market: 'other',
        code3: 'UKR'
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
        tz: 'Asia/Dubai',
        default_currency: '',
        market: 'other',
        code3: 'ARE'
    },
    {
        name: 'United States Minor Outlying Islands',
        code: 'UM',
        tz: 'Pacific/Honolulu',
        default_currency: '',
        market: 'other',
        code3: 'UMI'
    },
    {
        name: 'Uruguay',
        code: 'UY',
        tz: 'America/Montevideo',
        default_currency: '',
        market: 'other',
        code3: 'URY'
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
        tz: 'Asia/Samarkand',
        default_currency: '',
        market: 'other',
        code3: 'UZB'
    },
    {
        name: 'Vanuatu',
        code: 'VU',
        tz: 'Pacific/Efate',
        default_currency: '',
        market: 'other',
        code3: 'VUT'
    },
    {
        name: 'Venezuela',
        code: 'VE',
        tz: 'America/Caracas',
        default_currency: '',
        market: 'other',
        code3: 'VEN'
    },
    {
        name: 'Viet Nam',
        code: 'VN',
        tz: 'Asia/Bangkok',
        default_currency: '',
        market: 'other',
        code3: 'VNM'
    },
    {
        name: 'Virgin Islands, British',
        code: 'VG',
        tz: 'America/Port_of_Spain',
        default_currency: '',
        market: 'other',
        code3: 'VGB'
    },
    {
        name: 'Virgin Islands, U.S.',
        code: 'VI',
        tz: 'America/Port_of_Spain',
        default_currency: '',
        market: 'other',
        code3: 'VIR'
    },
    {
        name: 'Wallis and Futuna',
        code: 'WF',
        tz: 'Pacific/Wallis',
        default_currency: '',
        market: 'other',
        code3: 'WLF'
    },
    {
        name: 'Western Sahara',
        code: 'EH',
        tz: 'Africa/El_Aaiun',
        default_currency: '',
        market: 'other',
        code3: 'ESH'
    },
    {
        name: 'Yemen',
        code: 'YE',
        tz: 'Asia/Aden',
        default_currency: '',
        market: 'other',
        code3: 'YEM'
    },
    {
        name: 'Zambia',
        code: 'ZM',
        tz: 'Africa/Lusaka',
        default_currency: '',
        market: 'other',
        code3: 'ZMB'
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
        tz: 'Africa/Harare',
        default_currency: '',
        market: 'other',
        code3: 'ZWE'
    }
];

export default COUNTRIES;
